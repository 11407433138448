:root {
    --primary-color: #2EB093;
    
    --secondary-color: #646464;
    
    --auxiliary-color: #2EB093;
    
    --bg-color: #FFFFFF;
    
    --bg-secondary-color: #121212;

    --bg-auxiliary-color: #EDEDED;

    --bg-circle-color: #FFFFFF;

    --bg-modal-color: #FFFFFF;
    
    --dark-color: #3F3F3F;
    
    --text-primary: #1E1E1E;

    --text-secondary: #2EB093; 

    --text-tertiary: #FFFFFF; 
    
    --text-gray: #4F4F4F;
    
    --input-color: #1E1E1E;

    --bg-input-color: #FFFFFF;
    
    --info-color: #B6E3D6;

    --disabled-color: #e2e2e2;

    --card-color: #EEEEEE;

    --modal-border-color: #ADB5BD;

    --border-color: #2EB093;

    --bg-login-color: #2EB093;

    --bg-login-page-color: #2EB093;

  --card-button-navigation-color:#2EB093;
  --card-button-color:#2EB093;
  --card-button-border-color:#2EB093;
  --card-bg-icon-color:#2EB093;
  --card-text-primary-color:#2EB093;
  --card-bg-primary-color:#2EB093;
}